import React, { Component } from "react"
import { Link } from "gatsby"
import { RedButton } from "../components/button-red"
// import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"

export class GridSevenHomepage extends Component {
    render() {
        return (
            <div className="container mx-auto">
                <p className="text-lndred text-sm text-center lg:text-left px-5 xl:px-[210px]">Services</p>
                <h2 className="px-5 font-bold text-2xl lg:text-3xl text-center lg:text-left xl:px-[210px]">{this.props.title}</h2>
                <div className="px-5 pt-5 pb-20 xl:px-52 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center gap-9 md:gap-5 text-white">

                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-belt-repair-service/single-stage-seat-belts">
                            <GatsbyImage image={this.props.singleStage[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.singleStage[0].node.image.title} alt={this.props.singleStage[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Single Stage Seat Belt</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.singleStage[0].node.description.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/seat-belt-repair-service/single-stage-seat-belts" buttonText="Repair My Seat Belt" title="Explore Seat Belt Repair Service" alt="Explore Seat Belt Repair Service" invert />
                            </div>
                        </div>
                    </div>

                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-belt-repair-service/dual-stage-seat-belt">
                            <GatsbyImage image={this.props.dualStage[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.dualStage[0].node.image.title} alt={this.props.dualStage[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Dual Stage Seat Belt</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.dualStage[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/seat-belt-repair-service/dual-stage-seat-belt" buttonText="Repair My Seat Belt" title="Explore Seat Belt Repair Service" alt="Explore Seat Belt Repair Service" invert />
                            </div>
                        </div>
                    </div>

                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-belt-repair-service/triple-stage-seat-belt">
                            <GatsbyImage image={this.props.tripleStage[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.tripleStage[0].node.image.title} alt={this.props.tripleStage[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Triple Stage Seat Belt</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.tripleStage[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/seat-belt-repair-service/triple-stage-seat-belt" buttonText="Repair My Seat Belt" title="Explore Seat Belt Repair Service" alt="Explore Seat Belt Repair Service" invert />
                            </div>
                        </div>
                    </div>

                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-belt-repair-service/pretensioner">
                            <GatsbyImage image={this.props.pretensioner[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.pretensioner[0].node.image.title} alt={this.props.pretensioner[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Pretensioner</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.pretensioner[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/seat-belt-repair-service/pretensioner" buttonText="Repair My Seat Belt" title="Explore Seat Belt Repair Service" alt="Explore Seat Belt Repair Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-belt-coloring-service/seat-belt-coloring">
                            <GatsbyImage image={this.props.seatBeltColor[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white max-h-60" title={this.props.seatBeltColor[0].node.image.title} alt={this.props.seatBeltColor[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Seat Belt Coloring</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.seatBeltColor[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/seat-belt-coloring-service/seat-belt-coloring" buttonText="Change My Webbing" title="Explore Seat Belt Coloring Service" alt="Explore Seat Belt Coloring Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-belt-webbing-replacement-service/seat-belt-webbing-replacement">
                            <GatsbyImage image={this.props.seatBeltWebbing[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white max-h-60" title={this.props.seatBeltWebbing[0].node.image.title} alt={this.props.seatBeltWebbing[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Webbing Replacement</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.seatBeltWebbing[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/seat-belt-webbing-replacement-service/seat-belt-webbing-replacement" buttonText="Replace My Webbing" title="Explore Seat Belt Webbing Replacement Service" alt="Explore Seat Belt Webbing Replacement Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-belt-coloring-service/seat-belt-coloring">
                            <GatsbyImage image={this.props.seatCoverStitching[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white max-h-60" title={this.props.seatCoverStitching[0].node.image.title} alt={this.props.seatCoverStitching[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Deployed Seat Repair</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.seatCoverStitching[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/deployed-seat-repair-service/deployed-seat-repair/" buttonText="Repair My Stitching" title="Explore Deployed Seat Repair Service" alt="Explore Deployed Seat Repair Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/seat-reupholstering-service/seat-reupholstering">
                            <GatsbyImage image={this.props.fullSeatReupholstering[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white max-h-60" title={this.props.fullSeatReupholstering[0].node.image.title} alt={this.props.fullSeatReupholstering[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Seat Reupholstering</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.fullSeatReupholstering[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/seat-reupholstering-service/seat-reupholstering" buttonText="Reupholster My Seat" title="Explore Seat Reupholstering Service" alt="Explore Seat Reupholstering Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/battery-cable-repair-service/battery-cable">
                            <GatsbyImage image={this.props.batteryCable[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.batteryCable[0].node.image.title} alt={this.props.batteryCable[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Battery Cable Repair</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.batteryCable[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/battery-cable-repair-service/battery-cable" buttonText="Repair My Fuse" title="Explore Battery Cable Repair Service" alt="Explore Battery Cable Repair Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/steering-column-sensor-repair-service/collapsible-steering-sensor">
                            <GatsbyImage image={this.props.steeringColumn[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.steeringColumn[0].node.image.title} alt={this.props.steeringColumn[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Steering Column Sensor Repair</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.steeringColumn[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/steering-column-sensor-repair-service/collapsible-steering-sensor" buttonText="Repair My Sensor" title="Explore Steering Column Sensor Repair Service" alt="Explore Steering Column Sensor Repair Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/srs-airbag-module-repair-service/airbag-module-reset">
                            <GatsbyImage image={this.props.srsAirbag[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.srsAirbag[0].node.image.title} alt={this.props.srsAirbag[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">SRS Airbag Module Repair</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.srsAirbag[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/srs-airbag-module-repair-service/airbag-module-reset" buttonText="Repair My Module" title="Explore SRS Airbag Module Repair Service" alt="Explore SRS Airbag Module Repair Service" invert />
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/convertible-roll-bar-repair-service/convertible-roll-bar">
                            <GatsbyImage image={this.props.convertibleRoll[0].node.image.gatsbyImageData} placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title={this.props.convertibleRoll[0].node.image.title} alt={this.props.convertibleRoll[0].node.image.description} />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Convertible Roll Bar Repair</h3>
                                <div className="text-sm pt-2" dangerouslySetInnerHTML={{ __html: this.props.convertibleRoll[0].node.serviceCardDescription.childMarkdownRemark.html }} />
                            </div>
                            <div className="pt-6 md:pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/convertible-roll-bar-repair-service/convertible-roll-bar" buttonText="Repair My Rollbars" title="Explore Convertible Roll Bar Repair Service" alt="Explore Convertible Roll Bar Repair Service" invert />
                            </div>
                        </div>
                    </div>
                    {/* <div className="max-w-sm mx-auto border shadow-xl rounded-lg bg-lndblue hover:border-lndred transition duration-150 ease-in-out">
                        <Link to="/services/deployed-headrest-repair-service/deployed-headrest">
                            <StaticImage src="../images/deployed-headrest-repair.jpeg" placeholder="none" className="rounded-t-lg h-auto z-0 bg-white" title="Deployed Headrest Repair Service" alt="Deployed Headrest Repair Service" />
                        </Link>
                        <div className="px-2">
                            <div className="h-24">
                                <h3 className="text-xl font-bold pt-5">Deployed Headrest Repair</h3>
                                <p className="text-sm pt-2">Popped out headrest repair using 100% OEM factory parts to restore it to functioning condition</p>
                            </div>
                            <div className="pt-6 md:pt-3 lg:pt-8 2xl:pt-4">
                                <RedButton buttonPage="/services/deployed-headrest-repair-service/deployed-headrest" buttonText="Repair My Headrest" title="Explore Deployed Headrest Repair Service" alt="Explore Deployed Headrest Repair Service" invert />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}