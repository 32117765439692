import React from "react"

const GoogleReviews = (props) => {
    return (
        <div className="bg-newlndgray pt-20 pb-24 relative">
            <div className="container mx-auto px-5 xl:px-[195px]">
                <h2 className="text-center lg:text-left text-sm text-lndred px-5">Google Reviews</h2>
                <p className="px-5 font-bold text-2xl lg:text-3xl text-center lg:text-left mb-10">Trusted By 1,000's Of Customers Each Year</p>
                <div className="lg:hidden">
                    <div src={props.reviewsCode}></div>
                </div>

                <div className="hidden lg:block 2xl:hidden">
                    <div src={props.reviewsCode}></div>
                </div>

                <div className="hidden 2xl:block">
                    <div src={props.reviewsCode}></div>
                </div>
            </div>
        </div>
    )
}
export default GoogleReviews;